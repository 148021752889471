import Draw from "ol/interaction/Draw";
import { createBox } from "ol/interaction/Draw";
import GeoJSON from "ol/format/GeoJSON";
import * as turf from "@turf/turf";
import proj4 from "proj4";
import Projection from "ol/proj/Projection";
// import { get as getProjection } from "ol/proj";
// import { getWidth, getHeight } from "ol/extent";
import { register } from "ol/proj/proj4";
// import { Fill, Stroke, Circle, Style } from "ol/style";

//define epsg 2326
proj4.defs(
  "EPSG:2326",
  "+proj=tmerc +lat_0=22.31213333333334 +lon_0=114.1785555555556 +k=1 +x_0=836694.05 +y_0=819069.8 +ellps=intl +towgs84=-162.619,-276.959,-161.764,0.067753,-2.24365,-1.15883,-1.09425 +units=m +no_defs"
);
register(proj4);

// let projection = getProjection("EPSG:2326");

let projection = new Projection({
  code: "EPSG:2326",
  units: "m",
  axisOrientation: "neu"
});

let extent = [783259.7, 799130.01, 872991.5360700004, 853188.3580900002];

let resolutions = [
  156543.03392800014,
  78271.51696399994,
  39135.75848200009,
  19567.87924099992,
  9783.93962049996,
  4891.96981024998,
  2445.98490512499,
  1222.992452562495,
  611.4962262813797,
  305.74811314055756,
  152.87405657041106,
  76.43702828507324,
  38.21851414253662,
  19.10925707126831,
  9.554628535634155,
  4.77731426794937,
  2.388657133974685,
  1.1943285668550503,
  0.5971642835598172,
  0.29858214164761665,
  0.14929107082380833
];

// const resolutions = [
//   194.88816337802564,
//   97.44408168901282,
//   48.72204084450641,
//   24.361020422253205,
//   12.180510211126602,
//   6.090255105563301,
//   3.0451275527816506,
//   1.5225637763908253,
//   0.7612818881954126,
//   0.3806409440977063,
//   0.1903204720488532,
//   0.0951602360244266,
//   0.0475801180122133,
//   0.0237900590061066,
//   0.0118950295030533,
//   0.0059475147515267,
//   0.0029737573757633,
//   0.0014868786878817,
//   7.434393439408e-4,
//   3.717196719704e-4,
//   1.858598359852e-4,
//   9.29299179926e-5
// ];

let hkorigin = [-4786700.0, 8353100.0];

const proj2326Obj = {
  projection,
  extent,
  resolutions,
  hkorigin
};

let addDraw = function(type, source) {
  if (type === "Rectangle") {
    return new Draw({
      source: source,
      type: "Circle",
      geometryFunction: createBox()
    });
  }
  return new Draw({
    source: source,
    type: type
  });
};

let convertToTurf = function(olFeature) {
  let geometryObj = new GeoJSON().writeGeometryObject(olFeature.getGeometry());
  switch (olFeature.getGeometry().getType()) {
    case "Polygon":
      return turf.polygon(geometryObj.coordinates);
    case "MultiPolygon":
      return turf.polygon(geometryObj.coordinates[0]);
    default:
      return undefined;
  }
};

let getArea = function(olFeature) {
  switch (olFeature.getGeometry().getType()) {
    case "Polygon":
      return olFeature.getGeometry().getArea();

    default:
      return undefined;
  }
};

let writeTurfGeometry = function(TurfFeature) {
  return TurfFeature.geometry;
};

export { addDraw, convertToTurf, writeTurfGeometry, getArea, proj2326Obj };
