<template>
  <div class="basemap-container">
    <h4 class="basemap__title">{{ localizedContent.basemap.title }}</h4>
    <div class="basemap__group">
      <div
        tabindex="0"
        :class="['base-map', { selected: currentMap === 'shp' }]"
        @keyup.enter.stop="() => changeBaseMap(0)"
        @click.stop="changeBaseMap(0)"
        ref="basemap-shp"
        id="basemap-shp"
      >
        <span>{{ localizedContent.basemap.shp }}</span>
      </div>
      <div
        tabindex="0"
        :class="['base-map', { selected: currentMap === 'raster' }]"
        @keyup.enter.stop="() => changeBaseMap(1)"
        @click.stop="changeBaseMap(1)"
        ref="basemap-raster"
        id="basemap-raster"
      >
        <span tabindex="-1">
          {{ localizedContent.basemap.raster | fmrRaster1 }}
          <br />
          {{ localizedContent.basemap.raster | fmrRaster2 }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BaseLayerSwitcher",
  computed: {
    ...mapGetters({
      localizedContent: "localize/content",
      currentMap: "basemap/currentMap",
      fontSize: "app/fontSize"
    })
  },
  filters: {
    fmrRaster1(text) {
      return text.split("|")[0];
    },
    fmrRaster2(text) {
      return text.split("|")[1];
    }
  },
  methods: {
    changeBaseMap(mapType) {
      this.mapType = mapType;

      if (mapType === 0) {
        this.$store.commit("basemap/set_current_map", "shp");
      } else {
        this.$store.commit("basemap/set_current_map", "raster");
      }
    }
  },
  data() {
    return {
      mapType: 0
    };
  },
  async mounted() {}
};
</script>

<style lang="less">
.basemap-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;

  width: 100%;
  .basemap__title {
    box-sizing: border-box;
    margin: 0;
    padding: 0 15px;
    width: 100%;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;

    height: 40px;
    line-height: 40px;
    background-color: #fffef6;
    color: #000000;

    font-weight: normal;
    font-size: 0.87em;
  }

  .basemap__group {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 15px 0;

    .base-map {
      position: relative;
      border: solid 2px rgba(255, 255, 255, 0);
      margin: 0 5px;
      border-radius: 8px;
      width: 120px;
      height: 80px;
      cursor: pointer;
      border: solid 2px #ffffff;

      overflow: hidden;
      background-size: 100% 100%;
      background-repeat: no-repeat;

      &#basemap-raster {
        background-image: url(https://hk-icon.oss-cn-hongkong.aliyuncs.com/raster.png);
      }

      &#basemap-shp {
        background-image: url(https://hk-icon.oss-cn-hongkong.aliyuncs.com/shp.png);
      }

      &.selected {
        border: solid 2px #fdb814;
        span {
          background-color: #fdb814;
        }
      }

      span {
        position: absolute;
        padding: 5px;
        bottom: 0px;
        right: 0px;
        color: rgb(255, 255, 255);
        text-align: center;
        width: 100%;
        font-size: 0.8em;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .basemap-container {
    .basemap__group {
      .base-map {
        width: 100px;
        height: 60px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .basemap-container {
    .basemap__group {
      .base-map {
        width: 120px;
        height: 80px;
      }
    }
  }
}
</style>
