<template>
  <div class="seachBarNew" tabindex="3">
    <div class="auto-complete">
      <el-input
        @input="handleInput"
        @keyup.enter.native="handleSelect(singleResultReponse[0])"
        v-model="searchInput"
        :placeholder="localizedContent.search.placeholder"
        clearable
        tabindex="3"
        class="auto-complete-input"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="handleIconClick()"></i>
      </el-input>

      <div class="auto-complete-popover" v-show="popoverVisible">
        <!-- 单一选项的popover -->
        <div class="options-loading" v-if="loading"><i class="el-icon-loading"></i></div>

        <div class="options-single" v-show="!allResultVisible" ref="scrollBarContainerForSingle">
          <div class="single-item-container" v-if="singleResultReponse.length > 0">
            <div
              tabindex="3"
              class="single-item"
              v-for="(item, itemIndex) in singleResultReponse"
              :key="itemIndex"
              @keyup.enter.stop="handleSelect(item)"
              @click="handleSelect(item)"
            >
              {{ item.nameEN }} {{ item.nameZH }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PerfectScrollbar from "perfect-scrollbar";
import proj4 from "proj4";
import "perfect-scrollbar/css/perfect-scrollbar.css";

export default {
  name: "SearchBarNew",
  computed: {
    ...mapGetters({
      localizedContent: "localize/content",
      language: "localize/language"
    })
  },
  data() {
    return {
      loading: false,
      psSingle: null,
      searchInput: "",
      popoverVisible: false,
      singleResultReponse: [],
      allResultVisible: false,
      selectedItem: null
    };
  },
  methods: {
    debounce(func, wait) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        func();
      }, wait);
    },
    handleInput() {
      let that = this;
      if (that.searchInput === "" || that.searchInput.trim() === "") {
        that.popoverVisible = false;
        that.searchInput = "";
        return;
      }
      this.debounce(() => {
        that.popoverVisible = true;
        that.singleResultReponse = [];
        that.allResultVisible = false;
        that.$refs.scrollBarContainerForSingle.scrollTop = 0;

        that.requestSingle();
      }, 400);
    },
    requestSingle() {
      let that = this;
      this.loading = true;
      this.axios
        .get("https://geodata.gov.hk/gs/api/v1.0.0/locationSearch", {
          params: {
            q: this.searchInput.trim()
          }
        })
        .then((response) => {
          that.singleResultReponse = response.data;
          this.loading = false;
        })
        .catch((err) => {
          // that.$message.error(that.localizedContent.message.error);
          console.error(err);
          this.loading = false;
        });
    },
    handleIconClick() {
      this.handleInput();
    },
    /* popover下拉列表选中的事件 */
    handleSelect(selectItem) {
      this.selectedItem = selectItem;
      let popupContent = [
        {
          label: this.localizedContent.search.address,
          value: this.language === "ENG" ? this.selectedItem.addressEN : this.selectedItem.addressZH
        },
        {
          label: this.localizedContent.search.place_name,
          value: this.language === "ENG" ? this.selectedItem.nameEN : this.selectedItem.nameZH
        }
      ];

      let position = proj4("EPSG:2326", "EPSG:4326", [this.selectedItem.x, this.selectedItem.y]);

      this.$emit("popup", {
        position: position,
        popupContent: popupContent
      });

      this.popoverVisible = false;
    }
  },

  watch: {
    language(nv) {
      if (this.selectedItem === null) {
        return;
      }

      let popupContent;
      if (nv === "ENG") {
        popupContent = [
          {
            label: this.localizedContent.search.address,
            value: `${this.selectedItem.addressEN}`
          },
          {
            label: this.localizedContent.search.place_name,
            value: `${this.selectedItem.nameEN}`
          }
        ];
      } else {
        popupContent = [
          {
            label: this.localizedContent.search.address,
            value: this.selectedItem.addressZH
          },
          {
            label: this.localizedContent.search.place_name,
            value: this.selectedItem.nameZH
          }
        ];
      }

      let position = proj4("EPSG:2326", "EPSG:4326", [this.selectedItem.x, this.selectedItem.y]);

      this.$emit("popup", {
        position: position,
        popupContent: popupContent
      });
    }
  },

  async mounted() {
    this.psSingle = new PerfectScrollbar(this.$refs.scrollBarContainerForSingle, {
      wheelSpeed: 0.5,
      wheelPropagation: true
    });
  }
};
</script>

<style lang="less" scoped>
.auto-complete {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.auto-complete-popover {
  margin-top: 5px;
  width: 100%;
  max-height: 300px;
  background-color: white;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
}

.options-loading {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #606060;
}

.options-single {
  position: relative;
  width: 100%;
  overflow: hidden;
  max-height: 300px;
}

.single-item-container {
  margin-top: 2px;
}

.single-item {
  margin-top: 6px;
  font-size: 0.88em;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-inline-start: 12px;
  cursor: pointer;
}

.single-item:hover {
  background-color: rgb(199, 199, 199);
}
</style>
