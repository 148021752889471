<template>
  <div :class="['map-control', { hide: hideMenu }]" id="map-control-menu">
    <div class="folder">
      <el-button size="mini" icon="el-icon-caret-left" @click="changeVisible(true)"></el-button>
    </div>
    <DrawTool class="draw-tool" />
    <SolarLayer class="solar-layer-switcher" />
    <Legend class="legend-area" />
    <Basemap class="base-layer-switcher" />
  </div>
</template>

<script>
import DrawTool from "./DrawTool/";
import SolarLayer from "./SolarLayer";
import Basemap from "./Basemap";
import Legend from "./Legend";

export default {
  name: "AsideBig",
  components: {
    DrawTool,
    SolarLayer,
    Basemap,
    Legend
  },
  methods: {
    changeVisible(visibility) {
      this.hideMenu = visibility;
    }
  },
  data() {
    return {
      hideMenu: true
    };
  }
};
</script>

<style lang="less">
.map-control {
  display: block;
  padding: 0;
  margin: 0;
  width: 320px;
  height: 100%;
  border-right: 1px solid #dddddd;
  background-color: #fbfeff;
  box-sizing: border-box;
  overflow-x: hidden;

  .folder {
    display: none;
    margin: 10px 15px;
    box-sizing: border-box;
    .el-button {
      width: 100%;
      font-size: 18px;
    }
  }
}

// @media screen and (max-width: 768px) {
//   .map-control {
//     width: 280px;
//   }
// }

@media screen and (max-width: 800px) {
  .map-control {
    z-index: 2003;
    position: absolute;
    left: 0;
    top: 0;
    width: 280px;

    &.hide {
      display: none;
    }

    .folder {
      display: block;
    }
  }
}
</style>
