import Draw from "ol/interaction/Draw";
import Feature from "ol/Feature";
import Event from "ol/events/Event.js";
import LineString from "ol/geom/LineString";
import GeometryLayout from "ol/geom/GeometryLayout";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
// import GeometryType from "ol/geom/GeometryType";
// import MultiLineString from "ol/geom/MultiLineString";
// import MultiPoint from "ol/geom/MultiPoint";
// import MultiPolygon from "ol/geom/MultiPolygon";
import { Fill, Stroke, Style, Text } from "ol/style";

const Mode = {
  POINT: "Point",
  LINE_STRING: "LineString",
  POLYGON: "Polygon",
  CIRCLE: "Circle"
};

// const getText = function(feature, resolution) {
const getText = function(feature) {
  return (
    feature
      .getGeometry()
      .getLength()
      .toFixed(2) + "m"
  );
};

function lineStyleFunction(feature, resolution) {
  return new Style({
    text: new Text({
      text: getText(feature, resolution),
      fill: new Fill({ color: "white" }),
      stroke: new Stroke({ color: "#000", width: 3 }),
      font:
        "bold 14px 'Helvetica Neue', 'Helvetica', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Arial', 'sans-serif'"
      // font: "bold 14px serif"
    })
  });
}

class DrawEvent extends Event {
  /**
   * @param {DrawEventType} type Type.
   * @param {Feature} feature The feature drawn.
   */
  constructor(type, feature) {
    super(type);

    /**
     * The feature being drawn.
     * @type {Feature}
     * @api
     */
    this.feature = feature;
  }
}

const DrawEventType = {
  /**
   * Triggered upon feature draw start
   * @event DrawEvent#drawstart
   * @api
   */
  DRAWSTART: "drawstart",
  /**
   * Triggered upon feature draw end
   * @event DrawEvent#drawend
   * @api
   */
  DRAWEND: "drawend",
  /**
   * Triggered upon feature draw abortion
   * @event DrawEvent#drawabort
   * @api
   */
  DRAWABORT: "drawabort"
};

class DrawWithLabel extends Draw {
  constructor(options) {
    super(options);
    this.map = options.map;
    this.labelOverlay_ = new VectorLayer({
      source: new VectorSource({
        useSpatialIndex: false,
        wrapX: false
      }),
      style: lineStyleFunction,
      updateWhileInteracting: true
    });
    this.map.addLayer(this.labelOverlay_);
    this.overlay_.setZIndex(600);
    this.labelOverlay_.setZIndex(900);
  }

  updateSketchFeatures_() {
    const overlaySource = this.overlay_.getSource();

    const sketchFeatures = [];

    if (this.sketchFeature_) {
      sketchFeatures.push(this.sketchFeature_);
    }
    if (this.sketchLine_) {
      sketchFeatures.push(this.sketchLine_);
    }
    if (this.sketchPoint_) {
      sketchFeatures.push(this.sketchPoint_);
    }
    const labelOverlaySource = this.labelOverlay_.getSource();
    const lineFeatures = [];
    if (this.sketchFeature_) {
      let geom = this.sketchFeature_.getGeometry();
      let coordinates = geom.getCoordinates()[0];
      if (coordinates.length > 2) {
        for (let lineNum = 1; lineNum <= coordinates.length - 2; lineNum++) {
          const start = coordinates[lineNum - 1];
          const end = coordinates[lineNum];
          let line = new LineString([...start, ...end], GeometryLayout.XY);
          let lineFeature = new Feature({
            geometry: line
          });
          lineFeatures.push(lineFeature);
        }
      }
    }

    labelOverlaySource.clear(true);
    overlaySource.clear(true);
    overlaySource.addFeatures(sketchFeatures);
    labelOverlaySource.addFeatures(lineFeatures);
  }

  startDrawing_(event) {
    const coordinate = event.coordinate || event;
    this.labelOverlay_.getSource().clear(true);
    const start = coordinate;
    const projection = this.map.getView().getProjection();
    this.finishCoordinate_ = start;

    if (this.mode_ === Mode.POINT) {
      this.sketchCoords_ = start.slice();
    } else if (this.mode_ === Mode.POLYGON) {
      this.sketchCoords_ = [[start.slice(), start.slice()]];
      this.sketchLineCoords_ = this.sketchCoords_[0];
    } else {
      this.sketchCoords_ = [start.slice(), start.slice()];
    }
    if (this.sketchLineCoords_) {
      this.sketchLine_ = new Feature(new LineString(this.sketchLineCoords_));
    }
    const geometry = this.geometryFunction_(this.sketchCoords_, undefined, projection);
    this.sketchFeature_ = new Feature();
    if (this.geometryName_) {
      this.sketchFeature_.setGeometryName(this.geometryName_);
    }
    this.sketchFeature_.setGeometry(geometry);
    this.updateSketchFeatures_();
    this.dispatchEvent(new DrawEvent(DrawEventType.DRAWSTART, this.sketchFeature_));
  }

  // 2021.5.11
  // Parameter past into this method change from object({coordinate, map}) to array(coordinate)
  // Probably bacause of the layer change but the source code from Wang Jun was to mass to mantain very clearly.
  // Fixed by change the logic as in startDrawing_above

  backup_startDrawing(event) {
    // TODO:
    // console.log(event);
    this.labelOverlay_.getSource().clear(true);
    const start = event.coordinate;
    const projection = event.map.getView().getProjection();
    this.finishCoordinate_ = start;
    if (this.mode_ === Mode.POINT) {
      this.sketchCoords_ = start.slice();
    } else if (this.mode_ === Mode.POLYGON) {
      this.sketchCoords_ = [[start.slice(), start.slice()]];
      this.sketchLineCoords_ = this.sketchCoords_[0];
    } else {
      this.sketchCoords_ = [start.slice(), start.slice()];
    }
    if (this.sketchLineCoords_) {
      this.sketchLine_ = new Feature(new LineString(this.sketchLineCoords_));
    }
    const geometry = this.geometryFunction_(this.sketchCoords_, undefined, projection);
    this.sketchFeature_ = new Feature();
    if (this.geometryName_) {
      this.sketchFeature_.setGeometryName(this.geometryName_);
    }
    this.sketchFeature_.setGeometry(geometry);
    this.updateSketchFeatures_();
    this.dispatchEvent(new DrawEvent(DrawEventType.DRAWSTART, this.sketchFeature_));
  }

  giveUpDrawing() {
    const sketchFeature = this.abortDrawing_();

    if (sketchFeature) {
      this.dispatchEvent(new DrawEvent(DrawEventType.DRAWABORT, sketchFeature));
    }
    this.labelOverlay_.getSource().clear();
  }
}

export default DrawWithLabel;
