<template>
  <div class="north-legend" @click="rotateNorth">
    <span>{{ localizedContent.north }}</span>
    <i class="ali-icon-location-north"></i>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NorthLegend",
  computed: {
    ...mapGetters({
      localizedContent: "localize/content"
    })
  },

  methods: {
    rotateNorth() {
      this.$emit("on-rotate-north");
    }
  },
  data() {
    return {};
  },
  async mounted() {}
};
</script>

<style lang="less" scoped>
.north-legend {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 36px;
  height: 45px;
  background-color: rgba(0, 60, 136, 0.5);
  margin: 0;
  padding: 1px;
  border-radius: 36px;

  cursor: pointer;
  span {
    font-size: 12px;
  }
}
</style>
