<template>
  <div class="aside-small">
    <ul>
      <li>
        <el-button icon="el-icon-menu" circle @click="showAsideBig"></el-button>
      </li>
      <li>
        <el-button
          type="primary"
          icon="el-icon-edit-outline"
          circle
          @click="drawToolClick(polygonConstant)"
        ></el-button>
      </li>
      <li><el-button icon="el-icon-delete" circle @click="clear()"></el-button></li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AsideSmall",
  components: {},

  computed: {
    ...mapGetters({
      localizedContent: "localize/content",
      rectangleConstant: "drawTool/rectangleConstant",
      polygonConstant: "drawTool/polygonConstant"
    }),
    drawToolActivate: {
      get() {
        return this.$store.getters["drawTool/drawToolActivate"];
      },
      set(val) {
        this.$store.commit("drawTool/set_draw_tool_activate", val);
      }
    },
    drawType: {
      get() {
        return this.$store.getters["drawTool/drawType"];
      },
      set(val) {
        this.$store.commit("drawTool/set_draw_type", val);
      }
    }
  },
  data() {
    return {
      hideMenu: false
    };
  },
  methods: {
    showAsideBig() {
      this.$emit("on-show-aside");
    },
    drawToolClick(type) {
      this.clear();
      let that = this;
      //如果按钮没被点击过（一次绘制过程尚未开始）
      if (this.drawToolActivate === false) {
        //激活绘制工具
        this.drawToolActivate = true;
        this.drawType = type;
      } else {
        //处于绘制过程中
        //需要重新开始一次绘制(模拟用户点击了当前type的按钮)
        //那么首先需要关闭当前绘制过程
        this.drawToolActivate = false;
        this.drawType = null;
        this.$nextTick(() => {
          that.drawToolClick(type);
        });
      }
    },
    clear() {
      this.$store.commit("drawTool/set_clear_state");
    }
  },
  async mounted() {}
};
</script>

<style lang="less">
.aside-small {
  display: none;
}
@media screen and (max-width: 800px) {
  .aside-small {
    display: block;
    z-index: 2001;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 10px 0;
        .el-button {
          box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}
</style>
