<template>
  <div class="draw-tool">
    <h4 class="draw-tool__title">
      {{ localizedContent.draw_tool.title }}
      <i
        :class="`ali-icon-help`"
        tabindex="0"
        @click="goUserGuideline"
        @keyup.enter="goUserGuideline"
      ></i>
    </h4>
    <div class="draw-tool__body">
      <button
        :class="['draw-tool__body--draw ', { active: activeClass }]"
        @click="drawToolClick(polygonConstant)"
        tabindex="-1"
      >
        <img
          :src="require('@/assets/images/irregular- rectangle.png')"
          alt="Draw"
          v-if="activeClass"
        />
        <i class="icon-font ali-icon-Box" v-else></i>
        {{ localizedContent.draw_tool.polygon }}
      </button>

      <button tabindex="-1" class="draw-tool__body--clear" @click="clear()">
        {{ localizedContent.draw_tool.clear }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DrawTool",
  computed: {
    ...mapGetters({
      localizedContent: "localize/content",
      rectangleConstant: "drawTool/rectangleConstant",
      polygonConstant: "drawTool/polygonConstant"
    }),
    drawToolActivate: {
      get() {
        return this.$store.getters["drawTool/drawToolActivate"];
      },
      set(val) {
        this.$store.commit("drawTool/set_draw_tool_activate", val);
      }
    },
    drawType: {
      get() {
        return this.$store.getters["drawTool/drawType"];
      },
      set(val) {
        this.$store.commit("drawTool/set_draw_type", val);
      }
    }
  },
  methods: {
    drawToolClick(type) {
      this.clear();
      let that = this;
      //如果按钮没被点击过（一次绘制过程尚未开始）
      if (this.drawToolActivate === false) {
        //激活绘制工具
        this.drawToolActivate = true;
        this.drawType = type;
        this.activeClass = true;
      } else {
        //处于绘制过程中
        //需要重新开始一次绘制(模拟用户点击了当前type的按钮)
        //那么首先需要关闭当前绘制过程
        this.drawToolActivate = false;
        this.drawType = null;

        this.$nextTick(() => {
          that.drawToolClick(type);
        });
      }
    },
    clear() {
      this.activeClass = false;
      this.$store.commit("drawTool/set_clear_state");
    },
    goUserGuideline() {
      // this.$router.push({ name: "GuideLineLayout" });
      window.open(this.$store.getters["localize/content"].guidelineLink);
    }
  },
  data() {
    return { activeClass: "" };
  },
  async mounted() {}
};
</script>

<style lang="less">
.draw-tool {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875em;

  &__title {
    box-sizing: border-box;
    margin: 0;
    padding: 0 15px;
    width: 100%;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;

    height: 40px;
    line-height: 40px;
    background-color: #fffef6;
    color: #000000;

    font-weight: normal;
    font-size: 0.87em;

    i {
      margin-left: 5px;
      &:hover {
        color: #fdb814;
      }
    }
  }

  &__body {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 20px 15px;
    box-sizing: border-box;
    button {
      font-size: 0.875em;
      cursor: pointer;
    }

    &--draw {
      flex: 1;
      margin-left: 15px;
      padding: 5px 0;
      width: 240px;
      text-decoration: dashed;
      // outline: none;
      border: 0;
      border-radius: 4px;
      border: 1px solid #ffd165;

      background-color: #fffef6;
      color: #303030;
      img {
        vertical-align: middle;
      }

      &.active,
      &:hover {
        color: #ffffff;
        background-color: #fdb814;
      }
    }

    &--clear {
      margin-left: 15px;
      margin-right: 15px;
      // outline: none;
      padding: 0;
      border: 0;
      vertical-align: bottom;
      background-color: rgba(0, 0, 0, 0);
      border-bottom: 1px dashed #aaa;
      color: #303030;
      &:hover {
        color: #fdb814;
        border-bottom: 1px dashed #fdb814;
      }
    }
  }
}
</style>
