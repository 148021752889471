<template>
  <div class="ol-popup calculator-pane">
    <solar-result-card
      @close="solarResultCardClose"
      @closePayback="paybackCardClose"
      @paybackButtonClick="paybackButtonClick"
      class="solar-result-card"
      :areaSelected="areaSelected"
      :areaSuitableForPVInstallation="areaSuitableForPVInstallation"
      :installationCapacity="installationCapacity"
      :electricityGeneration="electricityGeneration"
      :FitIncome="FitIncome"
      :averageSolarIrradiation="averageSolarIrradiation"
    ></solar-result-card>

    <payback-card
      v-if="paybackCardVisible"
      class="payback-card"
      @close="paybackCardClose"
      :installationCapacity="installationCapacity"
      :electricityGeneration="electricityGeneration"
      :solarConfig="solarConfig"
      :pvAverage="averageSolarIrradiation"
    ></payback-card>

    <div :class="['arrow', { 'center-left': paybackCardVisible }]"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PaybackCard from "./PaybackCard";
import SolarResultCard from "./SolarResultCard";
export default {
  props: ["content", "solarConfig", "pvAverage"],
  components: {
    PaybackCard,
    SolarResultCard
  },
  name: "OlPopupWithVnode",
  computed: {
    ...mapGetters({
      localizedContent: "localize/content",
      language: "localize/language"
    }),
    areaSelected() {
      if (this.content.areaSelected === 0) {
        return 0;
      } else {
        return this.content.areaSelected ? this.content.areaSelected : 1;
      }
    },
    areaSuitableForPVInstallation() {
      if (this.content.areaSuitableForPVInstallation === 0) {
        return 0;
      } else {
        return this.content.areaSuitableForPVInstallation
          ? this.content.areaSuitableForPVInstallation
          : 1;
      }
    },
    installationCapacity() {
      if (this.content.installationCapacity === 0) {
        return 0;
      } else {
        return this.content.installationCapacity ? this.content.installationCapacity : 1;
      }
    },
    electricityGeneration() {
      if (this.content.electricityGeneration === 0) {
        return 0;
      } else {
        return this.content.electricityGeneration ? this.content.electricityGeneration : 1;
      }
    },
    FitIncome() {
      if (this.content.FitIncome === 0) {
        return 0;
      } else {
        return this.content.FitIncome ? this.content.FitIncome : 1;
      }
    },
    averageSolarIrradiation() {
      if (this.content.averageSolarIrradiation === 0) {
        return 0;
      } else {
        return this.content.averageSolarIrradiation ? this.content.averageSolarIrradiation : 1;
      }
    }
  },
  methods: {
    solarResultCardClose() {
      this.$emit("popupWithVnodeClose");
      this.paybackCardVisible = false;
    },
    paybackButtonClick() {
      this.paybackCardVisible = true;
    },
    paybackCardClose() {
      this.paybackCardVisible = false;
    }
  },
  watch: {
    areaSelected() {
      this.paybackCardVisible = false;
    }
  },
  data() {
    return {
      paybackCardVisible: false
    };
  },
  async mounted() {}
};
</script>

<style lang="less">
.calculator-pane {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.35);
  transition: height 0.6s;

  .card-container {
    z-index: 2000;
    position: absolute;
    right: 15px;
    width: 25%;
    height: 100%;
    pointer-events: none;
  }

  .solar-result-card {
    width: 100%;
    pointer-events: auto;
  }

  .payback-card {
    width: 100%;
    pointer-events: auto;
  }

  .arrow {
    position: absolute;
    top: 50%;
    left: -10px;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    transform: translate(-50%, 0);

    border-color: transparent rgb(255, 255, 255) transparent transparent;

    &.center-left {
      top: 50%;
    }
  }
}

@media screen and (max-width: 540px) {
  .calculator-pane {
    width: 240px;
  }
}
@media screen and (max-width: 320px) {
  .calculator-pane {
    width: 240px;
  }
}
</style>
