import Projection from "ol/proj/Projection";
import WMTSTileGrid from "ol/tilegrid/WMTS";

const gridNames = [
  "EPSG:2326:0",
  "EPSG:2326:1",
  "EPSG:2326:2",
  "EPSG:2326:3",
  "EPSG:2326:4",
  "EPSG:2326:5",
  "EPSG:2326:6",
  "EPSG:2326:7",
  "EPSG:2326:8",
  "EPSG:2326:9",
  "EPSG:2326:10",
  "EPSG:2326:11",
  "EPSG:2326:12",
  "EPSG:2326:13",
  "EPSG:2326:14",
  "EPSG:2326:15",
  "EPSG:2326:16",
  "EPSG:2326:17",
  "EPSG:2326:18",
  "EPSG:2326:19",
  "EPSG:2326:20"
];

const gridsetName = "EPSG:2326";

const style = "";

const format = "image/png";

const projection = new Projection({
  code: "EPSG:2326",
  units: "m",
  axisOrientation: "neu"
});

const resolutions = [
  194.88816337802564,
  97.44408168901282,
  48.72204084450641,
  24.361020422253205,
  12.180510211126602,
  6.090255105563301,
  3.0451275527816506,
  1.5225637763908253,
  0.7612818881954126,
  0.3806409440977063,
  0.1903204720488532,
  0.0951602360244266,
  0.0475801180122133,
  0.0237900590061066,
  0.0118950295030533,
  0.0059475147515267,
  0.0029737573757633,
  0.0014868786878817,
  7.434393439408e-4,
  3.717196719704e-4,
  1.858598359852e-4,
  9.29299179926e-5
];

export default {
  VERSION: "1.0.0",
  STYLE: style,
  TILEMATRIX: gridNames,
  TILEMATRIXSET: gridsetName,
  SERVICE: "WMTS",
  FORMAT: format,
  PROJECTION: projection,
  TILE_GRID: new WMTSTileGrid({
    tileSize: [256, 256],
    extent: [793510.5441972963, 798901.882329949, 893293.2838468454, 848793.2521547235],
    origin: [793510.5441972963, 848793.2521547235],
    resolutions: resolutions,
    matrixIds: gridNames
  })
};
