<template>
  <div class="payback-pane">
    <el-card class="card" shadow="always">
      <div class="card-content">
        <div class="item">
          <div class="label">
            {{ localizedContent.payback_card.installation_capacity_label }}
          </div>
          <div class="content">
            <el-input
              v-model="installationCapacityUserInput"
              size="mini"
              type="Number"
              :placeholder="localizedContent.payback_card.installation_capacity_placeholder"
            ></el-input>
          </div>
        </div>
        <div class="item">
          <div class="label">
            {{ localizedContent.payback_card.installation_cost_label }}
          </div>
          <div class="content">
            <el-input
              v-model="installationCost"
              size="mini"
              :min="0"
              :max="999999999"
              type="Number"
              :placeholder="localizedContent.payback_card.installation_cost_placeholder"
              @input="checkNumber"
            ></el-input>
          </div>
        </div>
        <div class="item">
          <div class="label">
            {{ localizedContent.payback_card.annual_electricity_generation }}
          </div>
          <div class="content">
            {{ annualElectricityGeneration.toLocaleString("en-US") }}
            {{ localizedContent.units["kWh"] }}
          </div>
          <!-- <div class="content">{{ annualElectricityGeneration }} kW</div> -->
        </div>
        <div class="item" v-show="fitVisible">
          <div class="label">{{ localizedContent.payback_card.income }}</div>
          <div class="content">
            {{ income.toLocaleString("en-US") }} {{ localizedContent.units["hk$"] }}
          </div>
          <!-- <div class="content">HK$ {{ income }}</div> -->
        </div>
        <div class="item" v-show="showPayback">
          <div class="label">
            {{ localizedContent.payback_card.payback_period }}
          </div>
          <div class="content">{{ paybackPeriod.toLocaleString("en-US") }}</div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PaybackCard",
  props: ["installationCapacity", "electricityGeneration", "solarConfig", "pvAverage"],
  computed: {
    ...mapGetters({
      localizedContent: "localize/content",
      language: "localize/language"
    }),
    annualElectricityGeneration: function() {
      const performanceRatio = this?.solarConfig?.performanceRatio;
      const tilting = this.$store.getters["solarLayer/tiltingAngleValue"];
      const oritation = this.$store.getters["solarLayer/orientationValue"];
      const shading = this?.solarConfig?.[`sfs${tilting}a${oritation}`];

      let generation =
        parseFloat(this.installationCapacityUserInput) *
        this.pvAverage *
        performanceRatio *
        shading;

      let result = generation.toFixed(1);
      if (isNaN(result)) {
        result = 0;
      }
      return parseFloat(result);
    },
    income: function() {
      const installationCapacity = this.installationCapacityUserInput;

      let income = 0;
      const annualElectricityGeneration = this.annualElectricityGeneration;

      if (installationCapacity <= 10) {
        const incomeHigh = this.solarConfig?.incomeHigh;
        income = incomeHigh * annualElectricityGeneration;
      } else if (installationCapacity > 10 && installationCapacity <= 200) {
        const incomeMid = this.solarConfig?.incomeMid;
        income = incomeMid * annualElectricityGeneration;
      } else if (installationCapacity > 200 && installationCapacity <= 1000) {
        const incomeLow = this.solarConfig?.incomeLow;
        income = incomeLow * annualElectricityGeneration;
      }

      if (isNaN(income)) {
        return 0;
      }
      return parseFloat(income.toFixed(1));
    },
    paybackPeriod: function() {
      let result = parseFloat((this.installationCost / parseFloat(this.income)).toFixed(1));
      if (isNaN(result)) {
        result = 0;
      }
      return result;
    }
  },
  methods: {
    closeClick() {
      this.$emit("close");
    },
    checkNumber(value) {
      if (value < 0) {
        this.installationCost = 0;
      }
      if (value > 999999999) {
        this.installationCost = 999999999;
      }
    }
  },
  data() {
    return {
      installationCapacityUserInput: "",
      installationCost: "",
      fitVisible: true,
      showPayback: true
    };
  },
  watch: {
    installationCapacity() {
      this.installationCapacityUserInput = "";
      this.installationCost = "";
    },
    installationCapacityUserInput(value) {
      this.fitVisible = true;
      if (value > this.installationCapacity) {
        this.$msgbox({
          message: this.localizedContent.message.capacityError,
          type: "error",
          confirmButtonText: this.localizedContent.message.alertMessage
            .confirmButtonTextWithoutChinese
        });
      }

      if (value > 1000) {
        this.$msgbox({
          message: this.localizedContent.message.fitIncome,
          type: "warning",
          confirmButtonText: this.localizedContent.message.alertMessage
            .confirmButtonTextWithoutChinese
        });
      }

      if (this.installationCapacity < 1000 && value < 1000) {
        this.fitVisible = true;
        this.showPayback = true;
      } else if (this.installationCapacity > 1000 && value > 1000) {
        this.fitVisible = false;
        this.showPayback = false;
      } else if (this.installationCapacity > 1000 && value < 1000) {
        this.fitVisible = true;
        this.showPayback = true;
      }
    }
  },
  async mounted() {}
};
</script>

<style lang="less">
.payback-pane {
  .header {
    display: flex;
    align-items: center;
    span {
      flex-grow: 1;
      font-weight: bold;
      font-size: 0.8m;
    }
    .el-button {
      padding: 2px;
    }
  }

  .el-card {
    border-radius: 0;
    border: 0;
    box-shadow: none;
  }

  .el-card__header {
    padding: 10px;
  }
  .el-card__body {
    padding: 0;
  }

  .item {
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    flex-wrap: nowrap;
    box-sizing: border-box;
    font-size: 0.8em;
    letter-spacing: 1px;

    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
      "微软雅黑", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:nth-child(odd) {
      background-color: #fffef6;
    }
    .label {
      padding-left: 15px;
      flex-grow: 1;
    }
    .content {
      margin-right: 15px;
      flex-basis: 45%;
      flex-grow: 0;
      flex-shrink: 0;
      padding-left: 15px;
      .el-input__inner {
        padding-left: 5px;
        padding-right: 0;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .payback-pane {
    .header {
      padding: 15px 10px;
      span {
        font-size: 0.6em;
      }
    }
    .el-card__header {
      padding: 5px 10px;
    }
    .el-card__body {
      padding: 0;
    }
    .item {
      padding: 5px 0;
      font-size: 0.6em;

      .label {
        padding-left: 15px;
      }
    }
  }
}
</style>
