<template>
  <div class="legend__container">
    <h4 class="legend__title">{{ localizedContent.legend.title }}</h4>

    <div class="legend__body">
      <div class="legend__body--wrapper">
        <div class="legend__body--graphic"></div>
        <div class="legend__body--text">
          <span>{{ localizedContent.legend.excellent }}</span>
          <span>{{ localizedContent.legend.good }}</span>
          <span>{{ localizedContent.legend.fair }}</span>
          <span>{{ localizedContent.legend.poor }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Legend",
  computed: {
    ...mapGetters({
      localizedContent: "localize/content"
    })
  },
  methods: {},
  data() {
    return {};
  },
  async mounted() {}
};
</script>

<style lang="less">
.legend__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;

  width: 100%;

  .legend__title {
    box-sizing: border-box;
    margin: 0;
    padding: 0 15px;
    width: 100%;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;

    height: 40px;
    line-height: 40px;
    background-color: #fffef6;
    color: #000000;

    font-weight: normal;
    font-size: 0.87em;
  }

  .legend__body {
    flex: 1;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 15px 0;

    &--wrapper {
      width: 80%;
      min-height: 90px;
      display: flex;
      justify-content: space-between;
      border: 1px solid #dddddd;
    }

    &--graphic {
      width: 60px;
      background: linear-gradient(to top, #0000ff, #23ffbe, #fff800, #ff3a00);
    }

    &--text {
      height: 90px;
      flex: 1;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      color: #303030;
      font-size: 0.7em;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      span {
        flex: 1;
        padding: 5px;

        border-bottom: 1px solid #dddddd;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>
