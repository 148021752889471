import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ.js";
import { Stroke, Style, Fill } from "ol/style";
import GeoJSON from "ol/format/GeoJSON";
// import TileWMS from "ol/source/TileWMS";
import { bbox as bboxStrategy } from "ol/loadingstrategy";
import Vue from "vue";
import store from "@/store/index";
import { mapGetters } from "vuex";
import { proj2326Obj } from "./utils";
import TileGrid from "ol/tilegrid/TileGrid";
import WMTS from "ol/source/WMTS";

import WmtsEpsg2326Opts from "./wmtsEpsg2326Opts";

let tileGrid = new TileGrid({
  extent: proj2326Obj.extent,
  origin: proj2326Obj.hkorigin,
  resolutions: proj2326Obj.resolutions,
  tileSize: 256
});

let topographic_map_layer = new TileLayer({
  title: store.getters["localize/content"].topographic_map.layer_title,
  visible: true,
  opacity: 0.6,
  source: new XYZ({
    url:
      "https://api.hkmapservice.gov.hk/osm/xyz/basemap/HK80/tile/{z}/{x}/{y}.png?key=" +
      store.getters["config/landsdApiKey"],
    crossOrigin: "anonymous",
    tileGrid: tileGrid,
    attributions: [store.getters["localize/content"].topographic_map.attributions],
    attributionsCollapsible: false,
    projection: proj2326Obj.projection
  })
});

let topographic_map_label_layer = new TileLayer({
  title: store.getters["localize/content"].topographic_map_label.layer_title,
  visible: true,
  zIndex: 999,
  source: new XYZ({
    url:
      `https://api.hkmapservice.gov.hk/osm/xyz/label-${store.getters["localize/content"].topographic_map_label.lang}/HK80/tile/{z}/{x}/{y}.png?key=` +
      store.getters["config/landsdApiKey"],
    crossOrigin: "anonymous",
    projection: proj2326Obj.projection,
    tileGrid: tileGrid
  })
});

let satelite_map_layer = new TileLayer({
  title: store.getters["localize/content"].topographic_map.layer_title,
  visible: false,
  source: new XYZ({
    url:
      "https://api.hkmapservice.gov.hk/osm/xyz/imagery/HK80/tile/{z}/{x}/{y}.png?key=" +
      store.getters["config/landsdApiKey"],
    crossOrigin: "anonymous",
    attributions: [store.getters["localize/content"].topographic_map.attributions],
    attributionsCollapsible: false,
    projection: proj2326Obj.projection,
    tileGrid: tileGrid
  })
});

// let polyu_satelite_map_layer1 = new TileLayer({
//   title: store.getters["localize/content"].satelite_map.layer_title,
//   visible: false,
//   source: new WMTS({
//     // url: "https://hksolarmap.com/geoserver/gwc/service/wmts",
//     url: store.getters["config/WMTSBaseUrl"],
//     layer: "solarMap:kowloon",
//     matrixSet: WmtsEpsg2326Opts["TILEMATRIXSET"],
//     format: WmtsEpsg2326Opts["FORMAT"],
//     projection: WmtsEpsg2326Opts["PROJECTION"],
//     tileGrid: WmtsEpsg2326Opts["TILE_GRID"],
//     style: WmtsEpsg2326Opts["STYLE"],
//     wrapX: true
//   })
// });

// let polyu_satelite_map_layer2 = new TileLayer({
//   title: store.getters["localize/content"].satelite_map.layer_title,
//   visible: false,
//   source: new WMTS({
//     // url: "https://hksolarmap.com/geoserver/gwc/service/wmts",
//     url: store.getters["config/WMTSBaseUrl"],
//     layer: "solarMap:0504",
//     matrixSet: WmtsEpsg2326Opts["TILEMATRIXSET"],
//     format: WmtsEpsg2326Opts["FORMAT"],
//     projection: WmtsEpsg2326Opts["PROJECTION"],
//     tileGrid: WmtsEpsg2326Opts["TILE_GRID"],
//     style: WmtsEpsg2326Opts["STYLE"],
//     wrapX: true
//   })
// });

// const sateliteMaps = [satelite_map_layer, polyu_satelite_map_layer1, polyu_satelite_map_layer2];

let emsd_satelite_map_layer = new TileLayer({
  title: store.getters["localize/content"].satelite_map.layer_title,
  visible: false,
  source: new WMTS({
    url: "https://solarmap.emsd.gov.hk/geoserver/gwc/service/wmts",
    // url: store.getters["config/WMTSBaseUrl"],
    layer: "solarMap:hkbasemap",
    matrixSet: WmtsEpsg2326Opts["TILEMATRIXSET"],
    format: WmtsEpsg2326Opts["FORMAT"],
    projection: WmtsEpsg2326Opts["PROJECTION"],
    tileGrid: WmtsEpsg2326Opts["TILE_GRID"],
    style: WmtsEpsg2326Opts["STYLE"],
    wrapX: true
  })
});

const sateliteMaps = [satelite_map_layer, emsd_satelite_map_layer];

// for (let i = 0; i < 36; i++) {
//   sateliteMaps.push(
//     new TileLayer({
//       title: store.getters["localize/content"].satelite_map.layer_title,
//       visible: false,
//       source: new WMTS({
//         url: store.getters["config/WMTSBaseUrl"],
//         layer: `solarMap:basemap_${i}`,
//         matrixSet: WmtsEpsg2326Opts["TILEMATRIXSET"],
//         format: WmtsEpsg2326Opts["FORMAT"],
//         projection: WmtsEpsg2326Opts["PROJECTION"],
//         tileGrid: WmtsEpsg2326Opts["TILE_GRID"],
//         style: WmtsEpsg2326Opts["STYLE"],
//         wrapX: true
//       })
//     })
//   );
// }

// let satelite_map_layer = new TileLayer({
//   title: store.getters["localize/content"].topographic_map.layer_title,
//   visible: false,
//   source: new TileWMS({
//     url: store.getters["config/WMSBaseUrl"],
//     crossOrigin: "anonymous",
//     params: {
//       FORMAT: "image/png",
//       transparent: true,
//       VERSION: "1.1.1",
//       tiled: true,
//       LAYERS: `solarMap:test_v2`
//     }
//   })
// });

// let solar_layer = new TileLayer({
//   visible: store.getters["solarLayer/solorLayerVisible"],
//   source: new TileWMS({
//     url: store.getters["config/WMSBaseUrl"],
//     crossOrigin: "anonymous",
//     params: {
//       FORMAT: "image/png",
//       transparent: true,
//       VERSION: "1.1.1",
//       tiled: true,
//       LAYERS: `solarMap:s${store.getters["solarLayer/tiltingAngleValue"]}_a${store.getters["solarLayer/orientationValue"]}`
//     }
//   })
// });

let solar_layer = new TileLayer({
  title: store.getters["localize/content"].satelite_map.layer_title,
  visible: true,
  source: new WMTS({
    url: "https://solarmap.emsd.gov.hk/geoserver/gwc/service/wmts",
    // url: store.getters["config/WMTSBaseUrl"],
    layer: `solarMap:s${store.getters["solarLayer/tiltingAngleValue"]}_a${store.getters["solarLayer/orientationValue"]}`,
    matrixSet: WmtsEpsg2326Opts["TILEMATRIXSET"],
    format: WmtsEpsg2326Opts["FORMAT"],
    projection: WmtsEpsg2326Opts["PROJECTION"],
    tileGrid: WmtsEpsg2326Opts["TILE_GRID"],
    style: WmtsEpsg2326Opts["STYLE"],
    wrapX: true
  })
});

let building_layer = new VectorLayer({
  visible: true,
  minZoom: 16,
  // renderBuffer: 50,
  source: new VectorSource({
    format: new GeoJSON(),
    url: function(extent) {
      return (
        `${store.getters["config/WFSBaseUrl"]}service=WFS&` +
        `version=1.1.0&request=GetFeature&typename=solarMap:building&` +
        `outputFormat=application/json&srsname=EPSG:2326&bbox=${extent.join(",")},EPSG:2326`
      );
    },
    strategy: bboxStrategy
  }),
  style: new Style({
    stroke: new Stroke({
      color: "rgba(75,163,254, 1.0)",
      width: 4
    }),
    fill: new Fill({
      color: "rgba(0, 0, 0, 0)"
    })
  })
});

topographic_map_layer.set("id", "Digital Map");
topographic_map_label_layer.set("id", "Digital Map Label");

satelite_map_layer.set("id", "Satelite Image Map");
solar_layer.set("id", "Solar Layer");

building_layer.set("id", "building Layer");

store.commit("drawTool/set_building_layer", building_layer);

let layerGroup = [
  topographic_map_layer,
  topographic_map_label_layer,
  ...sateliteMaps,
  solar_layer,
  building_layer
];

let layersInstance = new Vue({
  store,
  data() {
    return {
      layers: layerGroup,
      solar_layer,
      building_layer
    };
  },
  computed: {
    ...mapGetters({
      language: "localize/language",
      sValue: "solarLayer/tiltingAngleValue",
      aValue: "solarLayer/orientationValue",
      solarLayerVisible: "solarLayer/solorLayerVisible",
      buildingLayerVisible: "solarLayer/buildingLayerVisible",
      currentMap: "basemap/currentMap"
    })
  },
  watch: {
    language() {
      for (let index = 0; index < layerGroup.length; index++) {
        const layer = layerGroup[index];
        let layerId = layer.getProperties().id;

        switch (layerId) {
          case "Digital Map": {
            layer
              .getSource()
              .setAttributions([store.getters["localize/content"].topographic_map.attributions]);
            layer.set("title", store.getters["localize/content"].topographic_map.layer_title);
            break;
          }
          case "Digital Map Label": {
            layer.setSource(
              new XYZ({
                url:
                  `https://api.hkmapservice.gov.hk/osm/xyz/label-${store.getters["localize/content"].topographic_map_label.lang}/HK80/tile/{z}/{x}/{y}.png?key=` +
                  store.getters["config/landsdApiKey"],
                crossOrigin: "anonymous",
                projection: proj2326Obj.projection,
                tileGrid: tileGrid
              })
            );
            break;
          }
          case "Satelite Image Map": {
            layer
              .getSource()
              .setAttributions([store.getters["localize/content"].satelite_map.attributions]);
            // layer.set("title", store.getters["localize/content"].topographic_map.layer_title);
            break;
          }
          default:
            break;
        }
      }
    },
    sValue() {
      solar_layer.setSource(
        new WMTS({
          url: "https://solarmap.emsd.gov.hk/geoserver/gwc/service/wmts",
          // url: store.getters["config/WMTSBaseUrl"],
          layer: `solarMap:s${store.getters["solarLayer/tiltingAngleValue"]}_a${store.getters["solarLayer/orientationValue"]}`,
          matrixSet: WmtsEpsg2326Opts["TILEMATRIXSET"],
          format: WmtsEpsg2326Opts["FORMAT"],
          projection: WmtsEpsg2326Opts["PROJECTION"],
          tileGrid: WmtsEpsg2326Opts["TILE_GRID"],
          style: WmtsEpsg2326Opts["STYLE"],
          wrapX: true
        })

        // new TileWMS({
        //   url: store.getters["config/WMSBaseUrl"],
        //   crossOrigin: "anonymous",
        //   params: {
        //     FORMAT: "image/png",
        //     transparent: true,
        //     VERSION: "1.1.1",
        //     tiled: true,
        //     LAYERS: `solarMap:s${store.getters["solarLayer/tiltingAngleValue"]}_a${store.getters["solarLayer/orientationValue"]}`
        //   }
        // })
      );
    },
    aValue() {
      solar_layer.setSource(
        // new TileWMS({
        //   url: store.getters["config/WMSBaseUrl"],
        //   crossOrigin: "anonymous",
        //   params: {
        //     FORMAT: "image/png",
        //     transparent: true,
        //     VERSION: "1.1.1",
        //     tiled: true,
        //     LAYERS: `solarMap:s${store.getters["solarLayer/tiltingAngleValue"]}_a${store.getters["solarLayer/orientationValue"]}`
        //   }
        // })
        new WMTS({
          url: "https://solarmap.emsd.gov.hk/geoserver/gwc/service/wmts",
          // url: store.getters["config/WMTSBaseUrl"],
          layer: `solarMap:s${store.getters["solarLayer/tiltingAngleValue"]}_a${store.getters["solarLayer/orientationValue"]}`,
          matrixSet: WmtsEpsg2326Opts["TILEMATRIXSET"],
          format: WmtsEpsg2326Opts["FORMAT"],
          projection: WmtsEpsg2326Opts["PROJECTION"],
          tileGrid: WmtsEpsg2326Opts["TILE_GRID"],
          style: WmtsEpsg2326Opts["STYLE"],
          wrapX: true
        })
      );
    },
    solarLayerVisible() {
      solar_layer.setVisible(store.getters["solarLayer/solorLayerVisible"]);
    },
    buildingLayerVisible() {
      building_layer.setOpacity(Number(store.getters["solarLayer/buildingLayerVisible"]));
    },
    currentMap: async val => {
      if (val === "shp") {
        topographic_map_layer.setVisible(true);
        satelite_map_layer.setVisible(false);
        sateliteMaps.forEach(layer => layer.setVisible(false));
        // topographic_map_label_layer.bringToFront();
      } else {
        topographic_map_layer.setVisible(false);
        satelite_map_layer.setVisible(true);

        sateliteMaps.forEach(layer => layer.setVisible(true));
        // topographic_map_label_layer.bringToFront();
      }
    }
  }
});

export default layersInstance;
